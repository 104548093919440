import { Col, Row, Space, Table, Tag, DatePicker, Card, Button } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import MotionHoc from "../../../utils/MotionHoc";
import dayjs from "dayjs";

const Main = () => {
  const [isModal, setIsModal] = useState(false);
  console.log(isModal);
  const { RangePicker } = DatePicker;

  const columns = [
    {
      title: "เลขสัญญา",
      dataIndex: "contno",
      key: "key",
      align: "center",
      render: (text) => (
        <Link
          onClick={() => {
            setIsModal(true);
          }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "เลขคดี",
      dataIndex: "age",
      key: "age",
      align: "center",
    },
    {
      title: "สถานที่ฟ้อง",
      dataIndex: "address",
      key: "address",
      align: "center",
    },
    {
      title: "สถานะ",
      key: "action",
      dataIndex: "tags",
      align: "center",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag !== "เจอทรัพย์" ? "geekblue" : "green";
            if (tag === "ไม่เจอทรัพย์") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag} style={{ textAlign: "center" }}>
                {tag.toUpperCase()}
                <br />
                {dayjs().format("DD/MM/YY")}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "การจัดการ",
      dataIndex: "tags",
      key: "acction",
      align: "center",
      render: (_, { tags }) => (
        <>
          <Button>
            <SearchOutlined
              style={{ color: "orange", fontSize: "16px" }}
              onClick={() => {
                setIsModal(true);
              }}
            />
          </Button>
        </>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      contno: "8-00001",
      age: 12345,
      address: "New York No. 1 Lake Park",
      tags: ["เจอทรัพย์"],
    },
    {
      key: "2",
      contno: "8-00002",
      age: 122355,
      address: "London No. 1 Lake Park",
      tags: ["เจอทรัพย์"],
    },
    {
      key: "3",
      contno: "8-00003",
      age: 123455,
      address: "Sydney No. 1 Lake Park",
      tags: ["เจอทรัพย์"],
    },
    {
      key: "4",
      contno: "8-00004",
      age: 12399,
      address: "New York No. 1 Lake Park",
      tags: ["สืบทรัพย์"],
    },
    {
      key: "2",
      contno: "8-00005",
      age: 123455,
      address: "London No. 1 Lake Park",
      tags: ["ไม่เจอทรัพย์"],
    },
    {
      key: "5",
      contno: "8-00006",
      age: 345523,
      address: "Sydney No. 1 Lake Park",
      tags: ["เจอทรัพย์"],
    },
    {
      key: "6",
      contno: "8-00007",
      age: 32435,
      address: "New York No. 1 Lake Park",
      tags: ["สืบทรัพย์"],
    },
    {
      key: "7",
      contno: "8-00008",
      age: 32145,
      address: "London No. 1 Lake Park",
      tags: ["ไม่เจอทรัพย์"],
    },
    {
      key: "8",
      contno: "8-00009",
      age: 32145,
      address: "Sydney No. 1 Lake Park",
      tags: ["สืบทรัพย์"],
    },
    {
      key: "9",
      contno: "8-00010",
      age: 22356,
      address: "New York No. 1 Lake Park",
      tags: ["ไม่เจอทรัพย์"],
    },
    {
      key: "10",
      contno: "8-00011",
      age: 235662,
      address: "London No. 1 Lake Park",
      tags: ["ไม่เจอทรัพย์"],
    },
    {
      key: "11",
      contno: "8-000012",
      age: 3293482,
      address: "Sydney No. 1 Lake Park",
      tags: ["สืบทรัพย์"],
    },
  ];
  return (
    <>
      <Card>
        <Row>
          <Col span={"24"} style={{ textAlign: "end" }}>
            <Space direction="vertical" size={12}>
              <RangePicker size="large" style={{ marginRight: "10px" }} />
            </Space>
            <Search
              placeholder="ค้นหาสัญญา"
              onSearch={"onSearch"}
              enterButton
              style={{
                width: 200,
              }}
              size="large"
            />
          </Col>
          <Col span={"24"}>
            <Table
              size="small"
              columns={columns}
              dataSource={data}
              scroll={{ x: 850 }}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

const MainInvestigateAssets = MotionHoc(Main);
export default MainInvestigateAssets;
